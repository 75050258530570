/**
 * processing
 */
.processing {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: .7;
}

.processing::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*background: rgba(255,255,255,0.5);*/
    z-index: 21;
}

.processing::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    margin-left: -16px;
    margin-top: -16px;
    border-radius: 50%;
    border: 5px solid;
    border-color: #7d7c7c transparent;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    z-index: 22
}
.processing--sm::after {
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;
    border-width: 3px;
}

@keyframes rotation {
    100% {
        transform: rotate(360deg)
    }
}

/**
 * domain-btn
 */
.domain-btn {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    color: #424242;
    text-align: center;
    cursor: pointer;
    background: transparent none;
    border: 1px solid #414141;
    white-space: nowrap;
    margin: 0;
    padding: 7px 10px;
    user-select: none;
    outline: none;
    transition: all .1s ease;
}
@media (max-width: 600px) {
    .domain-btn {
        padding: 5px 7px;
    }
}

.domain-btn:hover {
    color: #fff;
    background-color: #848383;
    border-color: #848383;
}
.domain-btn:active { box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) }

.domain-btn--unavailable {
    color: #fff;
    border-color: #a0a0a0;
    background-color: #a0a0a0;
}

.domain-btn--available {
    color: #000;
    border-color: #86c06a;
    background-color: transparent;
}

.domain-btn--selected {
    color: #fff;
    border-color: hsl(100, 47%, 50%);
    background-color: hsl(100, 47%, 50%);
}

.domain-btn--available:hover,
.domain-btn--selected:hover {
    color: #fff;
    border-color: hsl(100, 47%, 45%);
    background-color: hsl(100, 47%, 45%);
}

.domain-btn--loading:hover,
.domain-btn--loading {
    color: transparent;
    position: relative;
    cursor: not-allowed;
}
.domain-btn--loading::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid;
    border-color: hsl(100, 47%, 45%) transparent;
    animation: rotation 1s linear infinite;
}
.domain-btn--loading:hover::after,
.domain-btn--selected.domain-btn--loading::after {
    border-color: #fff transparent;
}

/**
 * Search filter checkbox
 */
.search-filter-checkbox {
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-right: 40px;
    user-select: none;
}
.search-filter-checkbox:hover {
    color: #d42229;
}
.search-filter-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}
.search-filter-checkbox i {
    margin-right: 8px;
}
.search-filter-checkbox span {
    display: inline-block;
    vertical-align: middle;
}


/**
 * More icon
 */
.more-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    border: 1px solid #c4c8c8;
    background-color: #fff;
    border-radius: 50%;
    color: #727474;
    transform: rotate(0deg);
    transition: background-color .1s ease, border-color .1s ease, transform .1s ease;
}
.more-icon--active {
    transform: rotate(180deg);
}
a:hover .more-icon,
button:hover .more-icon,
.more-icon:hover {
    border-color: #7c7c7c;
    color: #333;
    background-color: #eceeef;
}
.more-icon::before,
.more-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    border-bottom-right-radius: 3px;
    transition: border-color .1s ease;
}
.more-icon::before { margin-top: -2px }
.more-icon::after  { margin-top:  2px }
.more-icon--active::before,
.more-icon--active::after {
    transform: translate(-50%, -50%) rotate(45deg);
}

/**
 * Main button
 */
.d-button,
a.d-button,
button.d-button {
    display: inline-block;
    vertical-align: middle;
    outline: none;
    text-decoration: none;
    border: 0;
    border-radius: 0;
    padding: 11px 22px;
    color: #fff;
    background-color: #d42229;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    font-family: "Open Sans",sans-serif;
    font-weight: 400;
    max-width: 100%;
    cursor: pointer;
}

.d-button:active, .d-button:focus, .d-button:hover,
a.d-button:active, a.d-button:focus, a.d-button:hover,
button.d-button:active, button.d-button:focus, button.d-button:hover {
    color: #fff;
    background-color: #b60910;
}

.d-button--md {
    min-width: 180px;
}