/**
 * processing
 */
.processing {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: .7;
}

.processing::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*background: rgba(255,255,255,0.5);*/
    z-index: 21;
}

.processing::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    margin-left: -16px;
    margin-top: -16px;
    border-radius: 50%;
    border: 5px solid;
    border-color: #7d7c7c transparent;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    z-index: 22
}
.processing--sm::after {
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;
    border-width: 3px;
}

@-webkit-keyframes rotation {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

@keyframes rotation {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

/**
 * domain-btn
 */
.domain-btn {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    color: #424242;
    text-align: center;
    cursor: pointer;
    background: transparent none;
    border: 1px solid #414141;
    white-space: nowrap;
    margin: 0;
    padding: 7px 10px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
}
@media (max-width: 600px) {
    .domain-btn {
        padding: 5px 7px;
    }
}

.domain-btn:hover {
    color: #fff;
    background-color: #848383;
    border-color: #848383;
}
.domain-btn:active { box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125) }

.domain-btn--unavailable {
    color: #fff;
    border-color: #a0a0a0;
    background-color: #a0a0a0;
}

.domain-btn--available {
    color: #000;
    border-color: #86c06a;
    background-color: transparent;
}

.domain-btn--selected {
    color: #fff;
    border-color: hsl(100, 47%, 50%);
    background-color: hsl(100, 47%, 50%);
}

.domain-btn--available:hover,
.domain-btn--selected:hover {
    color: #fff;
    border-color: hsl(100, 47%, 45%);
    background-color: hsl(100, 47%, 45%);
}

.domain-btn--loading:hover,
.domain-btn--loading {
    color: transparent;
    position: relative;
    cursor: not-allowed;
}
.domain-btn--loading::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid;
    border-color: hsl(100, 47%, 45%) transparent;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
}
.domain-btn--loading:hover::after,
.domain-btn--selected.domain-btn--loading::after {
    border-color: #fff transparent;
}

/**
 * Search filter checkbox
 */
.search-filter-checkbox {
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-right: 40px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.search-filter-checkbox:hover {
    color: #d42229;
}
.search-filter-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}
.search-filter-checkbox i {
    margin-right: 8px;
}
.search-filter-checkbox span {
    display: inline-block;
    vertical-align: middle;
}


/**
 * More icon
 */
.more-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    border: 1px solid #c4c8c8;
    background-color: #fff;
    border-radius: 50%;
    color: #727474;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: background-color .1s ease, border-color .1s ease, -webkit-transform .1s ease;
    transition: background-color .1s ease, border-color .1s ease, -webkit-transform .1s ease;
    transition: background-color .1s ease, border-color .1s ease, transform .1s ease;
    transition: background-color .1s ease, border-color .1s ease, transform .1s ease, -webkit-transform .1s ease;
}
.more-icon--active {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
a:hover .more-icon,
button:hover .more-icon,
.more-icon:hover {
    border-color: #7c7c7c;
    color: #333;
    background-color: #eceeef;
}
.more-icon::before,
.more-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    border-bottom-right-radius: 3px;
    -webkit-transition: border-color .1s ease;
    transition: border-color .1s ease;
}
.more-icon::before { margin-top: -2px }
.more-icon::after  { margin-top:  2px }
.more-icon--active::before,
.more-icon--active::after {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
}

/**
 * Main button
 */
.d-button,
a.d-button,
button.d-button {
    display: inline-block;
    vertical-align: middle;
    outline: none;
    text-decoration: none;
    border: 0;
    border-radius: 0;
    padding: 11px 22px;
    color: #fff;
    background-color: #d42229;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    font-family: "Open Sans",sans-serif;
    font-weight: 400;
    max-width: 100%;
    cursor: pointer;
}

.d-button:active, .d-button:focus, .d-button:hover,
a.d-button:active, a.d-button:focus, a.d-button:hover,
button.d-button:active, button.d-button:focus, button.d-button:hover {
    color: #fff;
    background-color: #b60910;
}

.d-button--md {
    min-width: 180px;
}
#app__CartIcon {
    line-height: 1;
}

.cart-icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 0 !important;
    box-sizing: border-box;
}

.cart-icon__badge {
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    min-width: 14px;
    padding: 3px;
    font-family: 'Open Sans', 'sans-serif';
    font-size: 8px;
    line-height: 8px;
    margin: 0;
    font-weight: normal;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #d42229; /* #ffc600 */
    border-radius: 10px;
    font-style: normal;
}
.cart-icon__badge--visible {
    visibility: visible;
    opacity: 1;
}

.cart-icon__icon {
    display: block;
    box-sizing: border-box;
}
.heading {
    font-size: 16px;
    margin: 0 0 1em;
    padding-top: 10px;
}

.suggested-domains {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -20px;
}

@supports (display: grid) {
    .suggested-domains {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-gap: 20px 40px;
        margin: 0;
    }
}

.suggested-domains__item {
    box-sizing: border-box;
    flex: 1 1 25%;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 20px;
}
@media (max-width: 1780px) { .suggested-domains__item { flex-basis: 33.3333% } }
@media (max-width: 1430px) { .suggested-domains__item { flex-basis: 50% } }
@media (max-width: 1024px) { .suggested-domains__item { flex-basis: 100% } }

.suggested-domains__item--loading {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 35px;
    background: #f4f4f4;
}
.suggested-domains__item--loading::after {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #999 transparent;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
}

@supports (display: grid) {
    .suggested-domains__item {
        padding: 0;
    }
}

.suggested-domains__name {
    margin-right: auto;
    word-break: break-all;
    word-break: break-word;
    font-weight: 700;
    font-size: 13px;
}

.suggested-domains__price {
    margin: 0 10px;
    white-space: nowrap;
}
