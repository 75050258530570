.heading {
    font-size: 16px;
    margin: 0 0 1em;
    padding-top: 10px;
}

.suggested-domains {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -20px;
}

@supports (display: grid) {
    .suggested-domains {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-gap: 20px 40px;
        margin: 0;
    }
}

.suggested-domains__item {
    box-sizing: border-box;
    flex: 1 1 25%;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 20px;
}
@media (max-width: 1780px) { .suggested-domains__item { flex-basis: 33.3333% } }
@media (max-width: 1430px) { .suggested-domains__item { flex-basis: 50% } }
@media (max-width: 1024px) { .suggested-domains__item { flex-basis: 100% } }

.suggested-domains__item--loading {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 35px;
    background: #f4f4f4;
}
.suggested-domains__item--loading::after {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #999 transparent;
    animation: rotation 1s linear infinite;
}

@supports (display: grid) {
    .suggested-domains__item {
        padding: 0;
    }
}

.suggested-domains__name {
    margin-right: auto;
    word-break: break-all;
    word-break: break-word;
    font-weight: 700;
    font-size: 13px;
}

.suggested-domains__price {
    margin: 0 10px;
    white-space: nowrap;
}