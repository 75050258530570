#app__CartIcon {
    line-height: 1;
}

.cart-icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 0 !important;
    box-sizing: border-box;
}

.cart-icon__badge {
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    min-width: 14px;
    padding: 3px;
    font-family: 'Open Sans', 'sans-serif';
    font-size: 8px;
    line-height: 8px;
    margin: 0;
    font-weight: normal;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #d42229; /* #ffc600 */
    border-radius: 10px;
    font-style: normal;
}
.cart-icon__badge--visible {
    visibility: visible;
    opacity: 1;
}

.cart-icon__icon {
    display: block;
    box-sizing: border-box;
}